var config = {};

export var enviroment = 'prod' // 'prod' or 'dev'

config.API = {
    // url: 'dam.amfbakeryportal.com/api', 
    url: enviroment === 'prod' ? 'dam.amfbakeryportal.com/api' : 'amf-dam.dev.fuel.digital/api',
    user: 'api_user',
    key: '647f88322d0f842b2ecafc9aefcc43ea0e4632857c956ff04b6b0e604e2d62fd'
};
config.IndexedDB = {
    dbname: 'resources',
    dbversion: 3
};
config.EmailFrom = "dboxey@amfbakery.com";
config.DownloadDir = "/";
config.ResourceSpaceApiKey = "a49c985a074786d251fd08552a810aaf76fe143a9fb72319d977917cc0dbe0bb";
config.ResourceSpaceEmailFrom = "sales@amfbakery.com";
config.ResourceSpaceApiUser = "admin";
config.MetadataMapping = {
    clients: [
        {
            name: 'amf',
            metaDataToLoad: 6,
            extraDepth: true,
            unitEquipmentRefsToHide: ['649', '650'], // filter out certain unit equipments in the search page
            solutionRefsToHide: ['613'],
            singleLevelRefs: [], //Only show breadcrumb links to self and home
            set2SubsystemRefs: enviroment === 'prod' ? ['291', '292', '293', '905'] : ['291', '292', '293', '673'], // move certain subsystems to the Services & Support section in the footer (292 and 293 redirect to the unit equipments themselves)
            refsToHideAssets: [], // filter on click of unit equipment record - passed as prop to Page Header component (title and Assets button). if match, will hide assets button
            redirectRefs: enviroment === 'prod' ? [ // custom redirect rules in subsystem component
                { from:'292', to: '/unitequipment/649' },
                { from:'293', to: '/unitequipment/650' },
                { from:'905', to: '/unitequipment/903' }
            ] : [ // custom redirect rules in subsystem component
            { from:'292', to: '/unitequipment/649' },
            { from:'293', to: '/unitequipment/650' },
            { from:'673', to: '/unitequipment/674' }
        ],
            prodDetailRefs: [ '613', '596', '597', '315', '318', '325', '333', '332', '321',
                              '327', '334', '316', '472', '314', '319', '322', '324', '336', 
                              '335'],
            prodAccentImgs: [ // certain images have overlays above the footer
                {ref: '594', count: 3},
                {ref: '595', count: 3},
                {ref: '596', count: 3},
                {ref: '597', count: 3},
                {ref: '598', count: 3},
                {ref: '600', count: 3},
                {ref: '601', count: 3},
                {ref: '602', count: 2},
                {ref: '606', count: 3},
                {ref: '607', count: 3},
                {ref: '608', count: 2},
                {ref: '609', count: 3},
                {ref: '610', count: 2},
                {ref: '611', count: 2},
                {ref: '612', count: 3},
                {ref: '613'},
                {ref: '975', count:2},
            ],
            resourceTypes: [ // resource types match - names and ref are different
                {
                    id: 0,
                    name: 'Global',
                    ref: 0
                },
                {
                    id: 1,
                    name: 'Photos',
                    ref: 1
                },
                {
                    id: 2,
                    name: 'Brochures',
                    ref: 2
                },
                {
                    id: 3,
                    name: 'Videos',
                    ref: 3
                },
                {
                    id: 4,
                    name: 'Audio',
                    ref: 4
                },
                {
                    id: 5,
                    name: 'Products',
                    ref: 6
                },
                {
                    id: 6,
                    name: 'Technical Files',
                    ref: 7
                }
            ],
            validation: {
                validateProductSystemSolutions: true,
                validateBrands: true,
                validateSubsystems: true,
                validateCategories: true,
                validateModels: true,
                validateUnitEquipments: true,
            },
            metadata: [
                {
                    shorthand_name: 'keywords',
                    id: 1
                },
                {
                    shorthand_name: 'country',
                    id: 3
                },
                {
                    shorthand_name: 'title',
                    id: 8
                },
                {
                    shorthand_name: 'extract',
                    id: 9
                },
                {
                    shorthand_name: 'credit',
                    id: 10
                },
                {
                    shorthand_name: 'date',
                    id: 12
                },
                {
                    shorthand_name: 'caption',
                    id: 18
                },
                {
                    shorthand_name: '',
                    id: 25
                },
                {
                    shorthand_name: 'person',
                    id: 29
                },
                {
                    shorthand_name: 'originalfilename',
                    id: 51
                },
                {
                    shorthand_name: 'camera',
                    id: 52
                },
                {
                    shorthand_name: 'source',
                    id: 54
                },
                {
                    shorthand_name: 'text',
                    id: 72
                },
                {
                    shorthand_name: 'framerate',
                    id: 76
                },
                {
                    shorthand_name: 'videobitrate',
                    id: 77
                },
                {
                    shorthand_name: 'aspectratio',
                    id: 78
                },
                {
                    shorthand_name: 'videosize',
                    id: 79
                },
                {
                    shorthand_name: 'duration',
                    id: 80
                },
                {
                    shorthand_name: 'channelmode',
                    id: 81
                },
                {
                    shorthand_name: 'samplerate',
                    id: 82
                },
                {
                    shorthand_name: 'audiobitrate',
                    id: 83
                },
                {
                    shorthand_name: 'productbrand',
                    id: 85
                },
                {
                    shorthand_name: 'sub-system',
                    id: 86
                },
                {
                    shorthand_name: 'unitequipment',
                    id: 88
                },
                {
                    shorthand_name: 'category',
                    id: 89
                },
                {
                    shorthand_name: 'model',
                    id: 90
                },
                {
                    shorthand_name: 'productsystemsolutio',
                    id: 91
                },
                {
                    shorthand_name: 'primaryphoto',
                    id: 92
                },
                {
                    shorthand_name: 'technicalfiletype',
                    id: 94 //Dev: 93 / Prod: 94
                },
                {
                    shorthand_name: 'sortorder',
                    id: 95 //Dev: 94 / Prod: 95
                }
            ]
        },
        {
            name: 'rbs',
            metaDataToLoad: 5,
            extraDepth: false,
            unitEquipmentRefsToHide: ['432', '433', '434', '435', '436'],
            singleLevelRefs: [],
            set2SubsystemRefs: ['427', '428', '429', '430', '431'],
            refsToHideAssets: ['434', '435', '436', '433', '432'],
            redirectRefs: [
                { from:'427', to: '/unitequipment/434' },
                { from:'428', to: '/unitequipment/435' },
                { from:'429', to: '/unitequipment/436' },
                { from:'430', to: '/unitequipment/433' },
                { from:'431', to: '/unitequipment/432' }
            ],
            prodDetailRefs: [ '613', '596', '597', '315', '318', '325', '333', '332', '321',
                              '327', '334', '316', '472', '314', '319', '322', '324', '336', 
                              '335'],
            prodDetailLinks: [
                {
                    ref: '472', 
                    links: [ 
                        { type: 'unitequipment', ref: '352' },
                        { type: 'unitequipment', ref: '352' },
                        { type: 'unitequipment', ref: '352' },
                        { type: 'unitequipment', ref: '352' },
                        { type: 'unitequipment', ref: '352' },
                    ]
                }
            ],
            prodAccentImgs: [
                {ref: '315', count: 3},
                {ref: '325', count: 3},
            ],
            resourceTypes: [
                {
                    id: 0,
                    name: 'Global',
                    ref: 0
                },
                {
                    id: 1,
                    name: 'Photos',
                    ref: 1
                },
                {
                    id: 2,
                    name: 'Brochures',
                    ref: 2
                },
                {
                    id: 3,
                    name: 'Videos',
                    ref: 3
                },
                {
                    id: 4,
                    name: 'Audio',
                    ref: 4
                },
                {
                    id: 5,
                    name: 'End Products',
                    ref: 5
                },
                {
                    id: 6,
                    name: 'Technical Files',
                    ref: 6
                }
            ],
            validation: {
                validateProductSystemSolutions: true,
                validateBrands: true,
                validateSubsystems: true,
                validateCategories: false,
                validateModels: false,
                validateUnitEquipments: true,
            },
            metadata: [
                {
                    shorthand_name: 'keywords',
                    id: 1
                },
                {
                    shorthand_name: 'country',
                    id: 3
                },
                {
                    shorthand_name: 'title',
                    id: 8
                },
                {
                    shorthand_name: 'extract',
                    id: 9
                },
                {
                    shorthand_name: 'credit',
                    id: 10
                },
                {
                    shorthand_name: 'date',
                    id: 12
                },
                {
                    shorthand_name: 'caption',
                    id: 18
                },
                {
                    shorthand_name: '',
                    id: 25
                },
                {
                    shorthand_name: 'person',
                    id: 29
                },
                {
                    shorthand_name: 'originalfilename',
                    id: 51
                },
                {
                    shorthand_name: 'camera',
                    id: 52
                },
                {
                    shorthand_name: 'source',
                    id: 54
                },
                {
                    shorthand_name: 'text',
                    id: 72
                },
                {
                    shorthand_name: 'subject',
                    id: 73
                },
                {
                    shorthand_name: 'event',
                    id: 74
                },
                {
                    shorthand_name: 'emotion',
                    id: 75
                },
                {
                    shorthand_name: 'framerate',
                    id: 76
                },
                {
                    shorthand_name: 'videobitrate',
                    id: 77
                },
                {
                    shorthand_name: 'aspectratio',
                    id: 78
                },
                {
                    shorthand_name: 'videosize',
                    id: 79
                },
                {
                    shorthand_name: 'duration',
                    id: 80
                },
                {
                    shorthand_name: 'channelmode',
                    id: 81
                },
                {
                    shorthand_name: 'samplerate',
                    id: 82
                },
                {
                    shorthand_name: 'audiobitrate',
                    id: 83
                },
                {
                    shorthand_name: 'productbrand',
                    id: 84
                },
                {
                    shorthand_name: 'sub-system',
                    id: 85
                },
                {
                    shorthand_name: 'productsystemsolutio',
                    id: 86
                },
                {
                    shorthand_name: 'photogroup',
                    id: 87
                },
                {
                    shorthand_name: 'primaryphoto',
                    id: 89
                },
                {
                    shorthand_name: 'unitequipment',
                    id: 90
                },
                {
                    shorthand_name: 'technicalfiletype',
                    id: 91
                },
                {
                    shorthand_name: 'sortorder',
                    id: 92
                }
            ]
        }
    ]
}

module.exports = config;

