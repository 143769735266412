import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import queryString from 'query-string';

import { DefaultPlayer as Video } from 'react-html5video';
import ErrorUI from '../ui/error/component';
import BackButtonComponent from '../ui/back-button-component';
import DocumentComponent from './document-component';
import { ThemeContext } from '../../theme-context';
import { withRouter } from 'react-router';

import { SaveAsset } from '../application/actions';
import { sortAssetsByMetadata } from '../../helpers/sort';

const mapStateToProps = state => ({
    application: state.Application
});

const assetSources = {
    UNITEQUIPMENT: 1,
    PRODUCTS: 2
}

class AssetGallery extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showEmail: process.kiosk,
            showDownload: !process.kiosk,
            assetSource: assetSources.UNITEQUIPMENT,
            resourceType: "1",
            numPages: 0,
            pageNumber: 1,
            item: 0,
            resourceList: [],
            videoVol: 1
        }

        this.loadAssets_UnitEquipment = this.loadAssets_UnitEquipment.bind(this);
        this.loadAssets_Products = this.loadAssets_Products.bind(this);
    }

    componentWillMount() {
        let assetSource = assetSources.UNITEQUIPMENT;
        if (location.hash.indexOf('products') > -1) { assetSource = assetSources.PRODUCTS; }
        let values = queryString.parse(this.props.location.search);
        if (values.type) {
            this.setState({
                resourceType: values.type,
                item: parseInt(values.item)
            });
        }

        switch (assetSource) {
            case assetSources.PRODUCTS:
                this.loadAssets_Products();
                break;
            case assetSources.UNITEQUIPMENT:
            default:
                this.loadAssets_UnitEquipment();
                break;
        }
    }

    loadAssets_UnitEquipment() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let unitEquipmentMetadata = application.unitequipments.find(m => m.ref == ref);
        let unitEquipmentResources = application.resources.filter(r => r.fields 
            && r.fields.find(v => v.name == "unitequipment") 
            && r.fields.find(v => v.name == "unitequipment").value.split(',').indexOf(unitEquipmentMetadata.name) > -1);

        this.setState({
            resourceList: unitEquipmentResources
        });
    }

    loadAssets_Products() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let productMetadata = application.solutions.find(m => m.ref == ref);
        let productCollection = application.collections.find(c => c.name == productMetadata.name);
        let productResources = productCollection
            ? application.resources.filter(r => productCollection.resourceRefs ? productCollection.resourceRefs.indexOf(r.ref) > -1 : false)
            : [];

        this.setState({
            resourceList: productResources
        });
    }

    downloadPDF = (e, pdf, title) => {
        e.preventDefault();
        this.props.dispatch(SaveAsset(pdf, title));
    };

    handleSlideChange = i => {
        const { application } = this.props;
        const resourceType3 = application.resourceTypes[3]; // video/video
        if (this.state.resourceType == resourceType3.ref) {
            const beforeVid = document.getElementById(`video-${i-1}`);
            if(beforeVid) beforeVid.pause();
            const afterVid = document.getElementById(`video-${i+1}`);
            if(afterVid) afterVid.pause();
        }
        this.setState({ item: i });
    };

    renderCarousel(){
        const { application, match } = this.props;
        let { showEmail, showDownload, resourceType, resourceList, assetSource } = this.state;
        let { theme } = this.context;

        // fetch resource types by array index (amf/rbs)
        const resourceType1 = application.resourceTypes[1]; // photo/photo
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // 3d document/technical file
        const resourceTypes = [resourceType1.ref, resourceType2.ref, resourceType3.ref, resourceType5.ref];
        if (!process.kiosk) { resourceTypes.push(resourceType6.ref); }

        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let resourcesToRender = resourceList.filter(r => r.resource_type == resourceType && r.file_extension != "md");
        
        if (resourceType == resourceType1.ref && assetSource === assetSources.UNITEQUIPMENT)
            resourcesToRender.sort(sortAssetsByMetadata(["photogroup"], [application.photogroups]));
        else
            resourcesToRender.sort(sortAssetsByMetadata(["technicalfiletype","photogroup"], [application.technicalfiletypes, application.photogroups]));
        
        const elWidth = Math.floor(window.innerWidth * .75);
        const elHeight = Math.floor(window.innerHeight * .75);

        switch (application.currentClient) {
            case 'amf':
                return (<div className={`asset-gallery-container asset-gallery-container--${theme}`}>
                <BackButtonComponent />
                <div className={`asset-gallery asset-gallery--${theme}`}>
                    {resourceTypes.includes(parseInt(this.state.resourceType)) &&
                        <Carousel
                            className={`asset-gallery-items asset-gallery-items--${theme}`}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            width={`${elWidth}px`}
                            selectedItem={this.state.item}
                            onChange={this.handleSlideChange}
                            swipeable={false}
                        >
                            {resourcesToRender.map(
                                (r, i) => {
                                    switch (parseInt(this.state.resourceType)) {
                                        case application.resourceTypes.find(elem => elem.id === 1).ref:
                                        case application.resourceTypes.find(elem => elem.id === 5).ref:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight }}>
                                                    <div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`} style={{ backgroundImage: `url(${r.url_org})` }}></div>
                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                        <div>{r.field8}</div>
                                                        <div>
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.${r.file_extension}`) }}>Download <img className={`gallery-icon gallery-icon--${theme}`} src="img/ico-download-gallery.svg" /></a>)}
                                                            {/* update this to be shown in Kiosk mode only after testing is successful (change showDownload to showEmail) */}
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img src="img/ico-mail.svg" /></Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img src="img/ico-mail.svg" /></Link>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        case application.resourceTypes.find(elem => elem.id === 3).ref:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight }}>
                                                    <div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`}>
                                                        <Video
                                                            id={`video-${i}`}
                                                            width={elWidth}
                                                            height={elHeight - 46}
                                                            style={{ width: elWidth, height: elHeight - 46 }}
                                                            preload="auto"
                                                            poster={r.url_scr}
                                                            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                                        >
                                                            <source src={r.url_org} type={`video/mp4`} />
                                                            <p>Your browser does not support the video tag.</p>
                                                        </Video>
                                                    </div>
                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                    <div>{r.field8}</div>
                                                        <div>
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.mp4`) }}>Download <img className={`gallery-icon gallery-icon--${theme}`} src="img/ico-download-gallery.svg" /></a>)}
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img src="img/ico-mail.svg" /></Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img src="img/ico-mail.svg" /></Link>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        case application.resourceTypes.find(elem => elem.id === 2).ref:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight - 26, overflow: 'auto', overflowX: 'hidden' }}>
                                                    <div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`} style={{ height: 'auto' }}>
                                                        <DocumentComponent fileUri={r.url_org} width={elWidth} />
                                                    </div>
                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                    <div>{r.field8}</div>
                                                        <div>
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.${r.file_extension}`) }}>Download <img className={`gallery-icon gallery-icon--${theme}`} src="img/ico-download-gallery.svg" /></a>)}
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img src="img/ico-mail.svg" /></Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img src="img/ico-mail.svg" /></Link>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        case application.resourceTypes.find(elem => elem.id === 6).ref:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight }}>
                                                    <div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`} style={{ backgroundImage: `url(${r.url_scr})` }}></div>

                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                    <div>{r.field8}</div>
                                                        <div>
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.${r.file_extension}`) }}>Download <img className={`gallery-icon gallery-icon--${theme}`} src="img/ico-download-gallery.svg" /></a>)}
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img className="mail-ico" src="img/ico-mail.svg" /></Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}>Email <img src="img/ico-mail.svg" /></Link>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    }
                                }
                            )}
                        </Carousel>
                    }

                    {!resourceTypes.includes(parseInt(this.state.resourceType)) && <ErrorUI />}
                </div>
            </div>)

            case 'rbs':
                return (<div className={`asset-gallery-container asset-gallery-container--${theme}`}>
                <BackButtonComponent />

                <div className={`asset-gallery asset-gallery--${theme}`}>
                    {resourceTypes.includes(parseInt(this.state.resourceType)) &&
                        <Carousel
                            className={`asset-gallery-items asset-gallery-items--${theme}`}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            width={`${elWidth}px`}
                            selectedItem={this.state.item}
                            onChange={this.handleSlideChange}
                            swipeable={false}
                        >
                            {resourcesToRender.map(
                                (r, i) => {
                                    let resourceType = parseInt(this.state.resourceType);
                                    if(r.file_extension && r.file_extension.toUpperCase() == "PDF") { resourceType = 2; }
                                    switch (resourceType) {
                                        case application.resourceTypes.find(elem => elem.id === 1).id:
                                        case application.resourceTypes.find(elem => elem.id === 5).id:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight }}>
                                                    { i == this.state.item && (<div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`} style={{ backgroundImage: `url(${r.url_org})`, width: `${elWidth}px` }}></div>)}
                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                        <div className={`asset-gallery-items-item-file-title-conatiner asset-gallery-items-item-file-title-conatiner--${theme}`}>
                                                        <div>{r.field8}</div>
                                                        <div className={`asset-gallery-items-item-file-extension asset-gallery-items-item-file-extension--${theme}`}>{r.file_extension}</div>
                                                        </div>
                                                        <div>
                                                            {/* update this to be shown in Kiosk mode only after testing is successful (change showDownload to showEmail) */}
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}> <img className="rbs-mail-icon" src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}><img className="rbs-mail-icon" src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.${r.file_extension}`) }}><img className={`gallery-icon gallery-icon--${theme}`} src="img/rbs-download-ico.svg" />Download</a>)}
                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        case application.resourceTypes.find(elem => elem.id === 3).id:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight }}>
                                                    <div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`}>
                                                        <Video
                                                            id={`video-${i}`}
                                                            width={elWidth}
                                                            height={elHeight - 46}
                                                            style={{ width: elWidth, height: elHeight - 46 }}
                                                            preload="auto"
                                                            poster={r.url_scr}
                                                            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                                        >
                                                            <source src={r.url_org} type={`video/mp4`} />
                                                            <p>Your browser does not support the video tag.</p>
                                                        </Video>
                                                    </div>
                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                    <div className={`asset-gallery-items-item-file-title-conatiner asset-gallery-items-item-file-title-conatiner--${theme}`}>
                                                        <div>{r.field8}</div>
                                                        <div className={`asset-gallery-items-item-file-extension asset-gallery-items-item-file-extension--${theme}`}>{r.file_extension}</div>
                                                        </div>
                                                        <div>
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}><img className="rbs-mail-icon" src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}><img className="rbs-mail-icon" src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.mp4`) }}><img className={`gallery-icon gallery-icon--${theme}`} src="img/rbs-download-ico.svg" />Download</a>)}
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        case application.resourceTypes.find(elem => elem.id === 2).id:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight - 26, overflow: 'auto', overflowX: 'hidden' }}>
                                                    <div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`} style={{ height: 'auto', width: `${elWidth}px` }}>
                                                        { i == this.state.item && (<DocumentComponent fileUri={r.url_org} width={elWidth} />) }
                                                    </div>
                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                    <div className={`asset-gallery-items-item-file-title-conatiner asset-gallery-items-item-file-title-conatiner--${theme}`}>
                                                        <div>{r.field8}</div>
                                                        <div className={`asset-gallery-items-item-file-extension asset-gallery-items-item-file-extension--${theme}`}>{r.file_extension}</div>
                                                        </div>
                                                        <div>
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}><img className="rbs-mail-icon" src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}><img className="rbs-mail-icon" src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.${r.file_extension}`) }}><img className={`gallery-icon gallery-icon--${theme}`} src="img/rbs-download-ico.svg" />Download</a>)}
                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        case application.resourceTypes.find(elem => elem.id === 6).id:
                                            return (
                                                <div className={`asset-gallery-items-item asset-gallery-items-item--${theme}`} key={r.ref} style={{ height: elHeight }}>
                                                    <div className={`asset-gallery-items-item-inner asset-gallery-items-item-inner--${theme}`} style={{ backgroundImage: `url(${r.url_scr})`, width: `${elWidth}px` }}></div>
                                                    <div className={`asset-gallery-items-item-content asset-gallery-items-item-content--${theme}`}>
                                                    <div className={`asset-gallery-items-item-file-title-conatiner asset-gallery-items-item-file-title-conatiner--${theme}`}>
                                                        <div>{r.field8}</div>
                                                        <div className={`asset-gallery-items-item-file-extension asset-gallery-items-item-file-extension--${theme}`}>{r.file_extension}</div>
                                                        </div>
                                                        <div>
                                                            {showDownload && (<Link to={`/email/${r.ref}`} className={`download-link download-link--${theme}`}><img className="rbs-mail-icon"src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showEmail && (<Link to={`/request/${r.ref}`} className={`download-link download-link--${theme}`}><img className="rbs-mail-icon" src="img/rbs-mail-ico.svg" />Email</Link>)}
                                                            {showDownload && (<a href="#" className={`download-link download-link--${theme}`} onClick={e => { this.downloadPDF(e, r.url_org, `${r.field8}.${r.file_extension}`) }}><img className={`gallery-icon gallery-icon--${theme}`} src="img/rbs-download-ico.svg" />Download</a>)}
            
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    }
                                }
                            )}
                        </Carousel>
                    }

                    {!resourceTypes.includes(parseInt(this.state.resourceType)) && <ErrorUI />}
                </div>
            </div>)

        }
    }

    render() {
        const { application, match } = this.props;
        let { showEmail, showDownload, resourceType, resourceList } = this.state;
        let { theme } = this.context;

        // fetch resource types by array index (amf/rbs)
        const resourceType1 = application.resourceTypes[1]; // photo/photo
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // 3d document/technical file
        const resourceTypes = [resourceType1.ref, resourceType2.ref, resourceType3.ref, resourceType5.ref];
        if (!process.kiosk) { resourceTypes.push(resourceType6.ref); }

        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let resourcesToRender = resourceList.filter(r => r.resource_type == resourceType && r.file_extension != "md");

        const elWidth = window.innerWidth * .75;
        const elHeight = window.innerHeight * .75;

        if (resourcesToRender.length > 0) {
            return (
            this.renderCarousel()
            );
        } else {
            return (
                <div className={`asset-gallery-container asset-gallery-container--${theme}`}>
                    <BackButtonComponent />
                    <div className={`asset-gallery asset-gallery--${theme }`}>
                        <ErrorUI />
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(connect(mapStateToProps)(AssetGallery));
AssetGallery.contextType = ThemeContext;