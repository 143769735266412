import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg'
import Markdown from 'react-markdown';
import UnitEquipmentSmallComponent from '../ui/unitequipment-small-component';
import BreadcrumbsComponent from '../ui/breadcrumbs';
import BackButtonComponent from '../ui/back-button-component';
import PageHeader from '../ui/page-header/component';
import LoaderUI from '../ui/loader/component';
import ErrorUI from '../ui/error/component';
import { fetchMarkdown } from '../../helpers/markdownFetcher';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';

const mapStateToProps = state => ({
    application: state.Application
});

class ProductDetail extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            svgWidth: '100%',
            productContent: '',
            validResourceTypes: []
        };

        this.goBack = e => {
            e.preventDefault();

            let { history } = this.props;
            history.goBack();
        };

        this.setMarkdownContent = this.setMarkdownContent.bind(this);
    }

    componentWillMount() {
        let { application, match } = this.props;


        let ref = ((match && match.params) ? match.params.ref : '').toString();

        const product = application.solutions.find(s => s.ref == ref);
        let productCollection = application.collections.find(c => c.name == product.name);
        let productResources = productCollection ? application.resources.filter(r => productCollection.resourceRefs ? productCollection.resourceRefs.indexOf(r.ref) > -1 : false)
        : [];

        // fetch resource types by array index (amf/rbs)
        const resourceType1 = application.resourceTypes[1]; // photo/photo
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // 3d document/technical file

        //Determine what assets are available
        let possibleResourceTypes = [resourceType6, resourceType2, resourceType3, resourceType5];
        let validResourceTypes = [resourceType1];
        possibleResourceTypes.map(rt => {
            if (productResources.find(r => r.resource_type == rt.ref && r.file_extension != 'md')) { validResourceTypes.push(rt); }
        })

        this.setState({ validResourceTypes: validResourceTypes });

        let productContent = productResources.filter(r => r.resource_type == resourceType2.ref && r.file_extension == "md")[0];
        if (productContent && productContent.url_org) {
            fetchMarkdown(productContent.url_org, this.setMarkdownContent);
        }
    }

    handleImageClick = target => {
        const id = target.getAttribute('id');

        if (id !== null && id.search('button') >= 0) {
            const subSysRef = id.slice(id.indexOf('-') + 1);

            location.hash = `#/unitequipment/${subSysRef}`;
        } else {
            return false;
        }
    };

    setMarkdownContent(content) {
        this.setState({ productContent: content });


    }
    renderAccent() {
        let { application, match } = this.props;
        let { theme } = this.context;
        let ref = ((match && match.params) ? match.params.ref : '').toString();


        switch (application.currentClient) {

            case 'amf':
                var imgAccent = application.prodAccentImgs.find(s => s.ref == ref) ? `./img/product-accent-${ref}.jpg` : './img/product-accent.png';
                return (
                    <div>
                        <div className="product-detail-accent-image" style={{ backgroundImage: `url('${imgAccent}')` }}></div>
                    </div>
                )
            case 'rbs':
                var imgAccent = application.prodAccentImgs.find(s => s.ref == ref) ? `./img/product-accent-${ref}.png` : `./img/product-accent-${ref}.png`;
                return (
                    <div className={`rbs-product-detail-accent-image-container rbs-product-detail-accent-image-container--${theme}`}>
                        <img className="rbs-product-detail-accent-image" src={`${imgAccent}`} />
                    </div>)
        }
    }

    render() {
        let { application, match } = this.props;
        let { theme } = this.context;
        let { productContent, validResourceTypes } = this.state;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let subRef = ((match && match.params) ? match.params.subRef : '').toString();

        const product = application.solutions.find(s => s.ref == ref);
        const parentSolution = application.solutions.find(s => s.ref == product.parent);
        const ProductDetailSvg = `./img/product-${product.ref}-${subRef}.svg`;

        const productDetailRef = application.prodDetailRefs ? application.prodDetailRefs.find(r => r === product.ref) : null;

        let links = [
            {
                title: 'Home',
                url: '/'
            },
            {
                title: `${parentSolution.name}`,
                url: `/solutions/${parentSolution.ref}`
            },
            {
                title: `${product.name}`,
                url: productDetailRef ? `/product-detail/${product.ref}/1` : `/products/${product.ref}`
            }
        ];
        console.log(this.state.svgWidth);
        var imgAccent = application.prodAccentImgs.find(s => s.ref == ref) ? `./img/product-accent-${ref}.jpg` : './img/product-accent.png';
        var productDetailLinks = application.prodDetailLinks ? application.prodDetailLinks.find(l => l.ref === ref) : null;

        console.log('application:', application);

        return (
            <div className="product-detail">
                <BreadcrumbsComponent links={links} />

                <div className={`inner-width inner-width--${theme}`}>
                    <BackButtonComponent />
                    <PageHeader title={product.name} linkRoute={`/products/${ref}/`} resourceTypesToShow={validResourceTypes} resourceTypes={application.resourceTypes} />

                    {productContent && <Markdown source={productContent} />}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="product-detail-image-container" style={{ width: productDetailLinks ? '45%' : '100%' }}>
                            <div className={`product-detail-image product-detail-image--${theme}`} style={{ maxWidth: this.state.svgWidth }}>
                                <ReactSVG
                                    src={ProductDetailSvg}
                                    afterInjection={(err, svg) => {
                                        if (err) {
                                            console.error(err);
                                            return;
                                        } else {
                                            const bbox = svg.getBBox(),
                                                bboxWidth = bbox.width;
                                            if (bboxWidth > 0 && bboxWidth < 1440) {
                                                this.setState({ svgWidth: bbox.width });
                                            }
                                        }
                                    }}
                                    fallback={() => <ErrorUI />}
                                    loading={() => <div className="product-detail-loader"> <LoaderUI hidePercentageBar={true} /> </div>}
                                    onClick={e => {
                                        e.preventDefault();

                                        this.handleImageClick(e.target.parentNode);
                                    }}
                                />
                            </div>
                        </div>
                        {productDetailLinks && (<div style={{ width: '55%', height: 'auto', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {productDetailLinks.links.map(l => {
                                    let unitEquipmentMetadata = application[l.type + 's'].find(ai => ai.ref === l.ref);
                                    let unitEquipmentResource = application.resources.find(r => r.resource_type == "1"
                                        && r.fields.find(f => f.name == "primaryphoto" && f.value == "Enabled")
                                        && r.fields.find(f => f.name == l.type && f.value == unitEquipmentMetadata.name));
                                    if (!unitEquipmentResource) {
                                        unitEquipmentResource = application.resources.find(r => r.resource_type == resourceType1.ref);
                                    }
                                    return (
                                        <UnitEquipmentSmallComponent key={`ue${unitEquipmentMetadata.ref}`} id={unitEquipmentMetadata.ref} name={unitEquipmentMetadata.name} imageUrl={unitEquipmentResource.url_scr} />
                                    )
                                })}
                            </div>
                        </div>)}
                    </div>
                </div>
                {this.renderAccent()}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(ProductDetail));
ProductDetail.contextType = ThemeContext;