import * as constants from './constants';

let initialState = {
    isLoggedIn: false,
    loginErrorMessage: '',
    isLoading: true,
    forceReload: false,
    metaDataToLoad: 0, //6
    collectionsToLoad: 99,
    resourceChunksToLoad: [],
    resourcesToLoad: 99,
    resourcesTouched: [],
    solutions: [],
    brands: [],
    subsystems: [],
    categories: [],
    models: [],
    unitequipments: [],
    collections: [],
    resources: [],
    totalCollections: 0,
    totalResources: 0,
    currentBatch: 0,
    assetBatchCount: 0,
    counter: 0,
    collectionsComplete: false,
    resourcesComplete: false,
    currentClient: '',
    validation: {},
    metadataMapping: {},
    unitEquipmentRefsToHide: [],
    solutionRefsToHide: [],
    set2SubsystemRefs: [],
    refsToHideAssets: [],
    resourceTypes: [],
    extraDepth: false,
    redirectRefs: [],
    singleLevelRefs: [],
    prodDetailLinks: [],
    prodDetailRefs: []
};
if (process.target != 'web')
{
    initialState.assetChunksToLoad = [];
}

export default function Application(state = initialState, action) {
    switch(action.type) {
        // BEGIN METADATA //
        case constants.SET_METADATA_SOLUTIONS:
            return Object.assign({}, state, {
                solutions: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        case constants.SET_METADATA_BRANDS:
            return Object.assign({}, state, {
                brands: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        case constants.SET_METADATA_SUBSYSTEMS:
            return Object.assign({}, state, {
                subsystems: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        case constants.SET_METADATA_CATEGORIES:
            return Object.assign({}, state, {
                categories: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        case constants.SET_METADATA_MODELS:
            return Object.assign({}, state, {
                models: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        case constants.SET_METADATA_UNITEQIPMENTS:
            return Object.assign({}, state, {
                unitequipments: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        case constants.SET_METADATA_TECHNICALFILETYPES:
            return Object.assign({}, state, {
                technicalfiletypes: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        case constants.SET_METADATA_PHOTOGROUPS:
            return Object.assign({}, state, {
                photogroups: action.data,
                metaDataToLoad: state.metaDataToLoad == 0 ? 0 : state.metaDataToLoad - 1
            });
        // END METADATA //
        // BEGIN COLLECTIONS //
        case constants.SET_COLLECTIONS:
            // console.log(action.data.length);
            return Object.assign({}, state, {
                collections: action.data,
                collectionsToLoad: action.data.length,
                totalCollections: action.data.length,
            });
        case constants.UPDATE_COLLECTION:
            let collections = state.collections.slice(0);
            let indexOfCollection = collections.map(c => c.ref).indexOf(action.data.ref);
            if(indexOfCollection >= 0) {
                collections.splice(indexOfCollection, 1, action.data);
            } else {
                collections.push(action.data);
            }
            return Object.assign({}, state, {
                collections: collections,
                collectionsToLoad: state.collectionsToLoad == 0 ? 0 : state.collectionsToLoad - 1,
            });
        case constants.INCREMENT_COUNTER:
            return Object.assign({}, state, {
                counter: state.counter + 1
            });
        case constants.RESET_COUNTER:
            return Object.assign({}, state, {
                counter: 0
            });
        // END COLLECTIONS //
        // BEGIN RESOURCES //
        case constants.SET_RESOURCES:
            return Object.assign({}, state, {
                resources: action.data,
                resourcesToLoad: action.data.length,
                totalResources: action.data.length
            });
        case constants.UPDATE_RESOURCE:
            let resources = state.resources.slice(0);
            let indexOfResource = resources.map(r => r.ref).indexOf(action.data.ref);
            if(indexOfResource >= 0) {
                resources.splice(indexOfResource, 1, action.data);
            } else {
                resources.push(action.data);
            }
            return Object.assign({}, state, {
                resources: resources,
                resourcesToLoad: state.resourcesToLoad == 0 ? 0 : state.resourcesToLoad - 1
            });
        case constants.CLEAN_RESOURCE: {
            let resources = state.resources.filter(r => r.last_synced && r.last_synced > action.data);
            console.log(resources);
            return Object.assign({}, state, {
                resources: resources
            });
        }
        // END RESOURCES //
        // ELECTRON ONLY: BEGIN ASSETS //
        case constants.SET_ASSETS: {
            return Object.assign({}, state, {
                assetChunksToLoad: action.data
            });
        }
        case constants.SET_ASSET_BATCH_COUNT: {
            return Object.assign({}, state, {
                assetBatchCount: action.data
            });
        }
        case constants.SET_ASSET_CURRENT_BATCH: {
            return Object.assign({}, state, {
                currentBatch: state.currentBatch + 1
            });
        }
        // END ASSETS //
        case constants.BEGIN_LOADING: 
            return Object.assign({}, state, initialState);
        case constants.BEGIN_SYNC:
            let newState = Object.assign({}, state, initialState);
            newState.forceUpdate = true;
            return newState;
        case constants.COMPLETE_LOADING:
            return Object.assign({}, state, {
                isLoading: false
            });
        case constants.SET_USER_LOGGEDIN:
            return Object.assign({}, state, {
                isLoggedIn: action.data.valid,
                loginErrorMessage: action.data.message ? action.data.message : ''
            });
        case constants.SET_METADATA_MAPPING:
            return Object.assign({}, state, {
                currentClient: action.data.currentClient,
                metaDataToLoad: action.data.metaDataToLoad,
                validation: action.data.validation,
                metadataMapping: action.data.metadataMapping,
                unitEquipmentRefsToHide: action.data.unitEquipmentRefsToHide,
                solutionRefsToHide: action.data.solutionRefsToHide,
                set2SubsystemRefs: action.data.set2SubsystemRefs,
                refsToHideAssets: action.data.refsToHideAssets,
                resourceTypes: action.data.resourceTypes,
                extraDepth: action.data.extraDepth,
                redirectRefs: action.data.redirectRefs,
                singleLevelRefs: action.data.singleLevelRefs,
                prodAccentImgs: action.data.prodAccentImgs,
                prodDetailLinks: action.data.prodDetailLinks,
                prodDetailRefs: action.data.prodDetailRefs
            });
        case constants.COLLECTIONS_COMPLETE:
            return Object.assign({}, state, {
                collectionsComplete: true
            });
        case constants.RESOURCES_COMPLETE:
            return Object.assign({}, state, {
                resourcesComplete: true
            });
        default:
            return state;
    }
}