import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../../theme-context';

const mapStateToProps = state => ({
    application: state.Application
});

class PageHeader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false
        };
    }

    toggleAssetsMenu = e => {
        e.preventDefault();

        this.setState({
            showMenu: !this.state.showMenu
        });
    };

    render() {
        let {
            title,
            subTitle,
            showAssets = true,
            resourceTypesToShow = [],
            resourceTypes = [],
            linkRoute = '/'
        } = this.props;
        let { theme } = this.context;

        // fetch resource types by array index (amf/rbs)
        const resourceType1 = resourceTypes[1]; // photo/photo
        const resourceType2 = resourceTypes[2]; // document/brochure
        const resourceType3 = resourceTypes[3]; // video/video
        const resourceType5 = resourceTypes[5]; // products/end product
        const resourceType6 = resourceTypes[6]; // 3d document/technical file

        switch(this.props.application.currentClient) {
            case 'amf':
                return (
                    <div className={`page-header page-header--${theme}`}>
                        <div>
                            {title && <h1 className={`unit-search-page-title unit-search-page-title--${theme}`}>{title}</h1>}
                            {subTitle && <h2>{subTitle}</h2>}
                        </div>
                        {showAssets && <div className={`assets-menu assets-menu--${theme} ${this.state.showMenu ? 'active' : ''}`}>
                            <a className={`btn-primary btn-primary--${theme} assets-button assets-button--${theme}`} href='' onClick={this.toggleAssetsMenu}>
                                ASSETS <i className={`fa fa-caret-right fa-caret-right--${theme}`} />
                            </a>
                            <div className={`assets-menu-inner assets-menu-inner--${theme}`}>
                                <ul>
                                    { (resourceTypesToShow.some(elem => elem.id === 1)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType1.ref}`}><img src='./img/ico-photos.svg' />{resourceType1.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 6)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType6.ref}`}><img src='./img/ico-3dpdf.svg' />{resourceType6.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 2)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType2.ref}`}><img src='./img/ico-brochures.svg' />{resourceType2.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 3)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType3.ref}`}><img src='./img/ico-videos.svg' />{resourceType3.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 5)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType5.ref}`}><img src='./img/ico-products.svg' />{resourceType5.name}</Link></li>) }
                                </ul>
                            </div>
                        </div>}
                    </div>
                );
            case 'rbs':
                return (
                    <div className={`page-header page-header--${theme}`}>
                        <div>
                            {title && <h1 className={`unit-search-page-title unit-search-page-title--${theme}`}>{title}</h1>}
                            {subTitle && <h2>{subTitle}</h2>}
                        </div>
                        {showAssets && <div className={`assets-menu assets-menu--${theme} ${this.state.showMenu ? 'active' : ''}`}>
                            <a className={`btn-primary btn-primary--${theme} assets-button assets-button--${theme}`} href='' onClick={this.toggleAssetsMenu}>
                                ASSETS <i className={`fa fa-caret-right fa-caret-right--${theme}`} />
                            </a>
                            <div className={`assets-menu-inner assets-menu-inner--${theme}`}>
                                <ul>
                                    { (resourceTypesToShow.some(elem => elem.id === 1)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType1.id}`}><img src='./img/rbs-ico-photo.svg' />{resourceType1.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 6)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType6.id}`}><img src='./img/rbs-ico-technical.svg' />{resourceType6.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 2)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType2.id}`}><img src='./img/rbs-ico-brochure.svg' />{resourceType2.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 3)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType3.id}`}><img src='./img/rbs-ico-video.svg' />{resourceType3.name}</Link></li>) }
                                    { (resourceTypesToShow.some(elem => elem.id === 5)) && (<li className={`assets-menu-inner-text assets-menu-inner-text--${theme}`}><Link to={`${linkRoute}assets?type=${resourceType5.id}`}><img src='./img/rbs-ico-products.svg' />{resourceType5.name}</Link></li>) }
                                </ul>
                            </div>
                        </div>}
                    </div>
                );
        }
    }
}

export default withRouter(connect(mapStateToProps)(PageHeader));
PageHeader.contextType = ThemeContext;