import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { checkLoggedIn, logIn } from './actions';
import { ThemeContext } from '../../theme-context';

const mapStateToProps = state => ({
    isLoggedIn: state.Application.isLoggedIn,
    loginErrorMessage: state.Application.loginErrorMessage,
    application: state.Application
});

const initialValues = {
    username: '',
    password: '',
};

class LoginComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentWillMount() {
        let {dispatch} = this.props;
        dispatch(checkLoggedIn());
    }

    componentDidUpdate() {
        if(this.props.loginErrorMessage && this.state.formSubmitted) {
            this.setState({ formSubmitted: false });
        }
    }

    onSubmit(values, { resetForm }) {
        let { dispatch } = this.props;
        this.setState({ formSubmitted: true});
        dispatch(logIn(values.username, values.password));
    }

    goBack(e) {
        e.preventDefault();

        let { history } = this.props;
        history.goBack();
    }

    renderForm(initialValues, onSubmit, isSubmitting) {
        let { theme } = this.context;
        return (<Formik
            initialValues={initialValues}
            onSubmit={onSubmit}>
            <Form className="contact-form">
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="username">Username</label>
                    <Field
                        name="username"
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="password">Password</label>
                    <Field
                        name="password"
                        type="password"
                        className="form-control"
                    />
                </div>
                {isSubmitting 
                    ? (<button type="submit" disabled={true} className={`btn btn-primary btn-primary--${theme} float-right`}>Verifying...</button>)
                    : (<button type="submit" className={`btn btn-primary btn-primary--${theme} float-right`}>Log In</button>)
                }
            </Form>
        </Formik>);
    }
    renderLogInIcon(){
        let {application } = this.props;
        let { theme } = this.context;
        switch (application.currentClient) {
            case 'amf':
                return (<div>
                        <img className={`img-fluid mx-auto d-block footer-logo footer-logo--${theme}`} src="./img/logo-amf.png"/>

                        </div>)

            case 'rbs':
                return (<div>
                    <img className={`img-fluid mx-auto d-block footer-logo footer-logo--${theme}`} src="./img/rbs-logo-loader-2.svg"/>
                </div>)
        }
    }

    render() {
        let { loginErrorMessage } = this.props;
        let { formSubmitted } = this.state;

        return (
            <div>
                <div className="inner-width2">
                       {this.renderLogInIcon()}
                        <div className="login-row">
                            <div className="login-box">
                        { loginErrorMessage && (<div className="error-message">{loginErrorMessage}</div>) }
                        { this.renderForm(initialValues, this.onSubmit, formSubmitted) }
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(LoginComponent));
LoginComponent.contextType = ThemeContext;