import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../../theme-context';

import { beginSync } from '../../loader/actions';

const mapStateToProps = state => ({
    application: state.Application
});

class PrimaryNavigation extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showNav: false
        };

        this.toggleNav = () => {
            this.setState({
                showNav: !this.state.showNav
            });
        };

        this.onSyncClick = this.onSyncClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
    }
    
    onSyncClick(e) {
        let { dispatch } = this.props;
        dispatch(beginSync());
    }

    onDownloadClick(e) {
        let {history} = this.props;
        this.toggleNav();
        history.push('/download');
    }

    renderLink(item, itemType) {
        return (
            <li key={item.ref}>
                <Link
                    to={`/${itemType}/${item.ref}`}
                    onClick={this.toggleNav}
                >
                    {item.name}
                </Link>
            </li>
        );
    }
    renderNav(){
        let { application } = this.props;
        let products = application.solutions.filter(s => s.parent == '');
        let subsystems = application.subsystems.filter(s => s.parent == '' && application.set2SubsystemRefs.indexOf(s.ref) === -1);
        let set2Subsystems = application.subsystems.filter(s => s.parent == '' && application.set2SubsystemRefs.indexOf(s.ref) > -1);

        switch (application.currentClient) {
            case 'amf':
                return (
                    <nav id="primary-nav">
                <a id="primary-nav-toggle" href="#" onClick={e => {
                    e.preventDefault();
                    this.toggleNav();
                }}>
                    {!this.state.showNav ? <span id="primary-nav-toggle-icon"><img src="./img/ico-menu.svg" />MENU</span> : <span id="primary-nav-toggle-icon"><img src="./img/ico-close.svg" />CLOSE</span>}
                </a>

                {this.state.showNav &&
                    <div id="primary-nav-menu">
                        <Link
                            id="primary-nav-menu-home"
                            to="/"
                            onClick={this.toggleNav}
                        >
                            HOME
                        </Link>

                        <ul>
                            <li>
                                <h5 className="header">Products</h5>
                            </li>

                            {products.map(p => this.renderLink(p, "solutions"))}
                        </ul>

                        <ul>
                            <li>
                                <h5 className="header">Process</h5>
                            </li>

                            {subsystems.map(s => this.renderLink(s, "subsystems"))}
                        </ul>

                        <ul>
                            <li>
                                <h5 className="header">Services &amp; Support</h5>
                            </li>

                            {set2Subsystems.map(s => this.renderLink(s, "subsystems"))}
                        </ul>


                        <Link
                            id="primary-nav-menu-search"
                            to="/search"
                            onClick={this.toggleNav}
                        >
                            SEARCH UNIT EQUIPMENT<i className="fa fa-caret-right fa-caret-right--amf"></i>
                        </Link>

                        <button type="button" className=" sync-btn btn btn-square btn-md btn-outline-dark" onClick={this.onSyncClick}>
                        <img className="sync-btn-icon" src="img/noun_Sync_62222.svg"/>
                            SYNC ASSETS
                        </button>

                        {process.target == "web" && (
                            <button type="button" className=" sync-btn btn btn-square btn-md btn-outline-dark" onClick={this.onDownloadClick}>
                            <img className="download-btn-icon" src="img/ico-download.svg"/>
                                DOWNLOAD APP
                            </button>
                        )}
                    </div>
                }

                {this.state.showNav && <div id="primary-nav-overlay" onClick={e => {
                    e.preventDefault();
                    this.toggleNav();
                }}></div>}
            </nav>)

            case 'rbs':
                return (
                    <nav id="rbs-primary-nav">
                <a id="rbs-primary-nav-toggle" href="#" onClick={e => {
                    e.preventDefault();
                    this.toggleNav();
                }}>
                    {!this.state.showNav ? <span id="rbs-primary-nav-toggle-icon"><img src="./img/rbs-hamburger.svg" /></span> : <span id="rbs-primary-nav-toggle-icon"><img src="./img/rbs-close-menu.svg" /></span>}
                </a>

                {this.state.showNav &&
                    <div id="rbs-primary-nav-menu">
                        <Link
                            id="rbs-primary-nav-menu-home"
                            to="/"
                            onClick={this.toggleNav}
                        >
                            HOME
                        </Link>

                        <ul>
                            <li>
                                <h5 className="header">Product</h5>
                            </li>

                            {products.map(p => this.renderLink(p, "solutions"))}
                        </ul>

                        <ul>
                            <li>
                                <h5 className="header">Process Category</h5>
                            </li>

                            {subsystems.map(s => this.renderLink(s, "subsystems"))}
                        </ul>
                    </div>
                }

                {this.state.showNav && <div id="rbs-primary-nav-overlay" onClick={e => {
                    e.preventDefault();
                    this.toggleNav();
                }}></div>}
            </nav>)
                    
        }
    }

    render() {

        return (
            this.renderNav()
        );
    }
}

export default withRouter(connect(mapStateToProps)(PrimaryNavigation));
PrimaryNavigation.contextType = ThemeContext;