import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../../theme-context';
import DownloadComponent from '../../download/component';

const mapStateToProps = state => ({
    application: state.Application
});

class Footer extends PureComponent {
    constructor(props) {
        super(props);
    }

    renderLink(item, itemType) {
        return (<div key={item.ref} className="col-md-12">
            <Link to={`/${itemType}/${item.ref}`}>
                <h6>{item.name}</h6>
            </Link>
        </div>);
    }

    renderLinkList(items, itemType) {
        let { theme } = this.context;
        let itemsToRender = process.target === 'web' ? items : items.filter(i => i.url === undefined);
        return (
            <div className="col-md-12">
                <ul className={`footer-ul footer-ul--${theme}`}>
                    {itemsToRender.map(item => {
                        return (
                            <li key={item.ref}>
                                {item.url === undefined
                                    ? 
                                        <Link to={`/${itemType}/${item.ref}`}>
                                            <div>{item.name}</div>
                                        </Link>
                                    :
                                    <a target="_blank" rel="noopener noreferrer" href={item.url}>{item.name}<i className="fa fa-external-link-alt"></i></a>
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    renderFooter() {
        let { application } = this.props;
        let products = application.solutions.filter(s => s.parent == '');
        let subsystems = application.subsystems.filter(s => s.parent == '' && application.set2SubsystemRefs.indexOf(s.ref) === -1);
        let set2Subsystems = application.subsystems.filter(s => s.parent == '' && application.set2SubsystemRefs.indexOf(s.ref) > -1);
        let { theme } = this.context;

        switch (application.currentClient) {
            case 'amf':
                return (
                    <footer className={`row footer-menu-main-row footer-menu-main-row--${theme}`}>
                    <div className={`col-9 col-lg-9 col-sm-12 col-xs-12 footer-menu-col footer-menu-col--${theme}`}>
                        <div className={`row footer-menu-nested-row footer-menu-nested-row--${theme}`}>
                        <div className="col-md-4">
                            <div className="row">
                                <div className={`col-md-12 footer-menu-title footer-menu-title--${theme}`}>
                                    <h5>Products</h5>
                                </div>
                                <div className={`footer-menu-items footer-menu-items--${theme}`}>
                                    {products.map(p => this.renderLink(p, "solutions"))}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="row">
                                <div className={`col-md-12 footer-menu-title footer-menu-title--${theme}`}>
                                    <h5>Process</h5>
                                </div>
                                <div className={`footer-menu-items footer-menu-items--${theme}`}>
                                    {subsystems.map(s => this.renderLink(s, "subsystems"))}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="row">
                                <div className={`col-md-12 footer-menu-title footer-menu-title--${theme}`}>
                                    <h5>Services & Support</h5>
                                </div>
                                <div className={`footer-menu-items footer-menu-items--${theme}`}>
                                    {set2Subsystems.map(s => this.renderLink(s, "subsystems"))}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-xl-3 col-md-3 col-sm-12">
                <div className="footer-logo-and-link-container">
                <div className="footer-logo-and-link-container2">
                <div className="footer-logo-box">
                    <img className="img-fluid footer-logo" src="img/logo-amf.png" alt="amf logo" />
                </div>
                    {!process.kiosk && (<div className="footer-link justify-content-center">
                        { process.target == "web" 
                            ? (<a href="https://AMFBakery.com" target="_blank"><h6>AMFBakery.com</h6></a>)
                            : (<h6 onClick={() => { window.electron.shell.openExternal('https://AMFBakery.com'); }}>AMFBakery.com</h6>)
                        }
                        <i className="fa fa-external-link-alt"></i>                       
                        </div>)}
                    {!process.kiosk && (<div className="footer-version">{process.version}</div>)}
                    </div>
                    </div>
                </div>
            </footer>
                )
            case 'rbs':
                const services = set2Subsystems.filter(x => x.ref !== "431");
                const corporate = set2Subsystems.filter(x => x.ref === "431");
                const urlObject = {
                    ref: "www.readingbakery.com",
                    name: "ReadingBakery.com",
                    url: "https://www.readingbakery.com"
                }
                corporate.push(urlObject);

                return (
                    <footer className={`row footer-menu-main-row footer-menu-main-row--${theme}`}>
                    <div className={`col-12 col-lg-12 col-sm-12 col-xs-12 footer-menu-col footer-menu-col--${theme}`}>
                        <div className={`row footer-menu-nested-row footer-menu-nested-row--${theme}`}>
                        <div className="service-corporate-container">
                        <div className="service-row">
                            <div className="row">
                                <div className={`col-md-12 service-footer-menu-title service-footer-menu-title--${theme}`}>
                                    <div className={`service-footer-column-header service-footer-column-header--${theme}`}>Services & Support</div>
                                </div>
                                <div className={`service-footer-menu-items service-footer-menu-items--${theme}`}>
                                    {this.renderLinkList(services, "subsystems")}
                                </div>
                            </div>
                        </div>

                        <div className="corporate-column">
                            <div className="row">
                                <div className={`col-md-12 corporate-footer-menu-title corporate-footer-menu-title--${theme}`}>
                                    <div className={`corporate-footer-column-header corporate-footer-column-header--${theme}`}>Corporate</div>
                                </div>
                                <div className={`corporate-footer-menu-items corporate-footer-menu-items--${theme}`}>
                                    {this.renderLinkList(corporate, "subsystems")}

                                </div>
                            </div>
                        </div>
                        </div>
                        {process.target == "web"
                            ? (<div className="version-download-column">
                                <div className="row download-btn-row">
                                    <Link to={"/download"}>
                                        <div>
                                            <h5 className="version-number-text">Version: { process.version ? process.version : '1.0' }</h5>
                                            <button type="button" className={`download-btn download-btn--${theme} btn btn-md`}>
                                            <img className="download-btn-icon" src="img/rbs-download-ico.svg"/>
                                                DOWNLOAD APP
                                            </button>
                                        </div>
                                    </Link>
                                </div>
                            </div>)
                            : (<div className="version-download-column">
                            <div className="row download-btn-row">
                                <div>
                                    <h5 className="version-number-text">Version: { process.version ? process.version : '1.0' }</h5>
                                </div>
                            </div>
                        </div>)
                        }
                    </div>
                    </div>
            </footer>
                )
        }
    }

    render() {
        return (
                this.renderFooter()
        );
    }
}

export default withRouter(connect(mapStateToProps)(Footer));
Footer.contextType = ThemeContext;