import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../theme-context';
import { withRouter } from 'react-router';
import Markdown from 'react-markdown';
import { fetchMarkdown } from '../../helpers/markdownFetcher';
import { sortByMetadata } from '../../helpers/sort';

import BreadcrumbsComponent from '../ui/breadcrumbs';
import UnitEquipmentSmallComponent from '../ui/unitequipment-small-component';
import BackButtonComponent from '../ui/back-button-component';
import PageHeaderComponent from '../ui/page-header/component';


const mapStateToProps = state => ({
    application: state.Application
});

const isKiosk = process.kiosk;

class UnitEquipmentComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            unitEquipmentMetadata: null,
            unitEquipmentContent: '',
            mainUnitEquipment: null,
            relatedEquipment: [],
            products: [],
            brand: '',
            process: '',
            validResourceTypes: [],
            showAssets: true,
            description: null
        }
        this.setMarkdownContent = this.setMarkdownContent.bind(this);
    }

    componentWillMount() {
        this.setup();
    }

    componentDidUpdate(oldProps) {
        let { match } = this.props;
        let newRef = ((match && match.params) ? match.params.ref : '').toString();
        let oldRef = ((oldProps.match && oldProps.match.params) ? oldProps.match.params.ref : '').toString();
        if(newRef != oldRef) { this.setup(); }
    }

    setup() {
        let { application, match } = this.props;

        // fetch resource types by array index (amf/rbs)
        const resourceType1 = application.resourceTypes[1]; // photo/photo
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // 3d document/technical file

        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let unitEquipmentMetadata = application.unitequipments.find(m => m.ref == ref);
        let unitEquipmentName = unitEquipmentMetadata ? unitEquipmentMetadata.name : null;
        let unitEquipmentCollection = application.collections.find(c => c.name == unitEquipmentName);
        let description = unitEquipmentCollection && unitEquipmentCollection.description ? unitEquipmentCollection.description : null;
        let unitEquipmentResources = unitEquipmentCollection
            ? application.resources.filter(r => unitEquipmentCollection.resourceRefs.indexOf(r.ref) > -1)
            : [];
        //Try to find the "Primary Photo" otherwise, just grab the first photo available
        let mainUnitEquipment = unitEquipmentResources.find(r => r.resource_type == resourceType1.ref && r.fields.find(f => f.name == "primaryphoto" && f.value == "Enabled"));
        if (!mainUnitEquipment) {
            mainUnitEquipment = unitEquipmentResources.find(r => r.resource_type == resourceType1.ref);
        }

        let brand = '';
        let process = '';
        let relatedEquipment = [];

        if (mainUnitEquipment) {
            let brandField = mainUnitEquipment.fields.find(v => v.name == "productbrand");
            if (brandField) { brand = brandField.value; }

            let processField = mainUnitEquipment.fields.find(v => v.name == "sub-system");
            if (processField) { process = processField.value.split(',')[1]; }

            let categoryField = mainUnitEquipment.fields.find(v => v.name == "category");
            let modelField = mainUnitEquipment.fields.find(v => v.name == "model");
            //Find related equipment by same model (if set), otherwise fallback to finding related equipment by same category
            //Only get the resources marked as "primary photo"
            if (modelField && modelField.value) {
                relatedEquipment = application.resources.filter(r => r.resource_type == resourceType1.ref
                    && r.ref != mainUnitEquipment.ref
                    && r.fields.find(f => f.name == "primaryphoto" && f.value == "Enabled")
                    && r.fields.find(f => f.name == "model" && f.value == modelField.value));
            } else if (categoryField && categoryField.value) {
                relatedEquipment = application.resources.filter(r => r.resource_type == resourceType1.ref
                    && r.ref != mainUnitEquipment.ref
                    && r.fields.find(f => f.name == "primaryphoto" && f.value == "Enabled")
                    && r.fields.find(f => f.name == "category" && f.value == categoryField.value));
            } else if (processField && processField.value) {
                relatedEquipment = application.resources.filter(r => r.resource_type == resourceType1.ref
                    && r.ref != mainUnitEquipment.ref
                    && r.fields.find(f => f.name == "primaryphoto" && f.value == "Enabled"
                    && r.fields.find(f => f.name == "sub-system" && f.value == processField.value)));
            }
            //Loop through related equipment and get required values
            relatedEquipment = relatedEquipment.map(re => {
                let ueField = re.fields.find(f => f.name == "unitequipment");
                let ueRef = application.unitequipments.find(m => m.name == (ueField ? ueField.value : null));
                return {
                    ref: ueRef ? ueRef.ref : null,
                    name: ueField ? ueField.value : '',
                    imageUrl: re.url_scr,
                    order_by: ueRef ? ueRef.order_by : "0"
                }
            });
            //console.log(relatedEquipment);
            relatedEquipment.sort(sortByMetadata);
        }

        //Determine what assets are available
        let possibleResourceTypes = [resourceType2, resourceType3, resourceType5];
        if(!isKiosk) { possibleResourceTypes.push(resourceType6); }
        let validResourceTypes = [resourceType1];
        possibleResourceTypes.map(rt => {
            if(unitEquipmentResources.find(r => r.resource_type == rt.ref && r.file_extension != 'md')) { validResourceTypes.push(rt); }
        })

        let products = unitEquipmentResources.filter(r => r.resource_type == resourceType5.ref);

        let showAssets = application.refsToHideAssets.indexOf(ref) == -1;

        this.setState({
            unitEquipmentMetadata: unitEquipmentMetadata,
            mainUnitEquipment: mainUnitEquipment, 
            relatedEquipment: relatedEquipment,
            products: products,
            brand: brand,
            process: process,
            validResourceTypes: validResourceTypes,
            showAssets: showAssets,
            description: description
        });

        //Need to fetch the description content
        let unitEquipmentContent = unitEquipmentResources.find(r => r.resource_type == resourceType2.ref && r.file_extension == "md");
        if(unitEquipmentContent && unitEquipmentContent.url_org) {
            fetchMarkdown(unitEquipmentContent.url_org, this.setMarkdownContent);
        } else {
            this.setMarkdownContent("No Unit Equipment Content Available");
        }
    }

    buildBreadcrumbLinks(resource, subsystems, extraDepth, unitEquipmentMetadata, singleLevelRefs) {
        let singleLevel = singleLevelRefs.indexOf(unitEquipmentMetadata.ref) !== -1;
        let links = [{
            title: 'Home',
            url: '/'
        }];
        
        if (extraDepth) {
            let subsystemField = resource.fields.filter(v => v.name == "sub-system")[0];
            let processUrl = ''
            if (!singleLevel && subsystemField) {
                let subsystemFieldValues = subsystemField.value.split(',');
                let subsystem = subsystems.filter(s => s.parent == '' && s.name == subsystemFieldValues[0])[0];
                let process = subsystems.filter(s => s.parent !== '' && s.name == subsystemFieldValues[1])[0];
                if (subsystem) {
                    links.push({
                        title: subsystemFieldValues[0],
                        url: `/subsystems/${subsystem.ref}`
                    })
                }
                if (process) {
                    processUrl = `/subsystems/process/${process.ref}`;
                    links.push({
                        title: subsystemFieldValues[1],
                        url: processUrl
                    })
                }
            }
            let categoryField = resource.fields.filter(v => v.name == "category")[0];
            if (!singleLevel && categoryField && categoryField.value) {
                links.push({
                    title: categoryField.value,
                    url: processUrl
                });
            }
            let modelField = resource.fields.filter(v => v.name == "model")[0];
            if (!singleLevel && modelField && modelField.value) {
                links.push({
                    title: modelField.value,
                    url: processUrl
                });
            }
        } else {
            const parent = resource.fields.find(v => v.name == "sub-system").value.split(',')[0];
            const subsystem = subsystems.filter(s => s.name == parent)[0];
            
            if (!singleLevel && subsystem !== undefined) {
                let subsystemNav = {
                    title: subsystem.name,
                    url: `/subsystems/${subsystem.ref}`
                };
                links.push(subsystemNav)
            }

            if (this.state.process !== undefined) {
                let processNav = {
                    title: this.state.process,
                    url: `/subsystems/${subsystem.ref}`
                };
                links.push(processNav)
            }

            links.push(
            {
                title: resource.fields.filter(v => v.name == "unitequipment")[0].value,
                url: `/unitequipment/${unitEquipmentMetadata.ref}`
            });
        }

        return links;
    }

    setMarkdownContent(content) {
        this.setState({ unitEquipmentContent: content });
    }

    render() {
        let { application } = this.props;
        let { unitEquipmentMetadata, mainUnitEquipment, unitEquipmentContent, relatedEquipment, products, brand, process, validResourceTypes, showAssets, description } = this.state;
        let { theme } = this.context;
        let caretColor = '4D361E';
        if (theme === 'amf') { caretColor = 'E31B23'; }
        return (
            <div>
                <BreadcrumbsComponent links={this.buildBreadcrumbLinks(mainUnitEquipment, application.subsystems, application.extraDepth, unitEquipmentMetadata, application.singleLevelRefs)} />

                <div className="inner-width">
                    <BackButtonComponent />
                    <PageHeaderComponent title={unitEquipmentMetadata.name} subTitle={description === undefined ? null : description} showAssets={showAssets} linkRoute={`/unitequipment/${unitEquipmentMetadata.ref}/`} resourceTypesToShow={validResourceTypes} resourceTypes={application.resourceTypes} />
                    <div className="open-frame-mixer-row">
                        <div className="open-frame-mixer-text-column">
                            <div className={`open-frame-mixer-text open-frame-mixer-text--${theme}`}>
                                {brand
                                    ? (<h5 className={`open-frame-mixer-sub-title open-frame-mixer-sub-title--${theme}`}>{brand} <span className="open-frame-mixer-pipe"> | </span> {process}</h5>)
                                    : (<h5 className={`open-frame-mixer-sub-title open-frame-mixer-sub-title--${theme}`}>{process}</h5>)
                                }
                                {unitEquipmentContent
                                    ? (<Markdown source={unitEquipmentContent} />)
                                    : (<div>Loading Content...</div>)
                                }
                            </div>
                        </div>
                        <div className="open-frame-mixer-image-column">
                            <div className="unit-equipment-image" style={{ backgroundImage: `url(${mainUnitEquipment.url_scr})` }}></div>
                        </div>
                    </div>
                    { products.length > 0 && (<div className={`all-products-row all-products-row--${theme}`}>
                        {products.slice(0, 6).map(p => {
                            return (
                                <div key={`product${p.ref}`} className={`all-products-box all-products-box--${theme}`}>
                                    <div className={`all-products-img all-products-img--${theme}`} style={{ backgroundImage: `url(${p.url_thm})` }}></div>
                                </div>
                            );
                        })}
                        <Link to={`/unitequipment/${unitEquipmentMetadata.ref}/assets?type=${application.resourceTypes[5].ref}`}>
                            <div className={`view-all-products view-all-products--${theme}`}>
                                <div className="text-center">
                                    <h5 className={`view-all-products-text view-all-products-text--${theme}`}>View All Products <img className="fa view-all-products-icons" src={`data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='11px' viewBox='0 0 8 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 61 (89581) - https://sketch.com --%3E%3Ctitle%3ERectangle Copy 6%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Unit-Equipment-Detail-Asset-Drawer-Open' transform='translate(-953.000000, -700.000000)' fill='%23${caretColor}'%3E%3Cg id='Process-Link' transform='translate(846.000000, 672.000000)'%3E%3Cg id='Group-5'%3E%3Cpolygon id='Rectangle-Copy-6' transform='translate(111.055556, 33.250000) rotate(90.000000) translate(-111.055556, -33.250000) ' points='116.111111 36.5 106 36.5 111.055556 30'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E`}/></h5>
                                </div>
                            </div>
                        </Link>
                    </div>)}
                    {relatedEquipment.length > 0 && (<div>
                        <div className="row">
                            <div className="col-12">
                                <h4 className={`related-equipment-title related-equipment-title--${theme}`}>Related Equipment</h4>
                            </div>
                        </div>
                        <div className="row related-equipment-row">
                            {relatedEquipment.map(ue => { return (<UnitEquipmentSmallComponent key={`ue${ue.ref}`} id={ue.ref} name={ue.name} imageUrl={ue.imageUrl} />) })}
                        </div>
                    </div>)}
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(UnitEquipmentComponent));
UnitEquipmentComponent.contextType = ThemeContext;