import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThemeContext } from '../../theme-context';
import { EmailFrom } from '../../config';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import BackButtonComponent from '../ui/back-button-component';
import { RequestAsset } from '../application/actions';

const mapStateToProps = state => ({
    application: state.Application
});

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    assetName: '',
    resourceType: '',
    unitEquipment: '',
};

class RequestsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeElementName: '',
            activeElementTitle: '',
            activeElementValue: '',
            showKeyboard: false,
            formSubmitted: false,
            layoutName: "default"
        }
        this.form = React.createRef();
        this.keyboardForm = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.showKeyboard = this.showKeyboard.bind(this);
        this.onInputChanged = this.onInputChanged.bind(this);
        this.onInputSubmitted = this.onInputSubmitted.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.handleShift = this.handleShift.bind(this);
    }

    showKeyboard(el, title) {
        if(!process.kiosk) { return; }
        if(typeof el === 'boolean') { this.setState({showKeyboard: el}); }
        else {
            this.setState({
                activeElementName: el.target.name,
                activeElementTitle: title,
                showKeyboard: true
            })
        }
    }

    onInputChanged = (data) => {
        this.keyboardForm.current.setFieldValue("keyboardInput", data);
    }
    onInputSubmitted = (data) => {
        this.form.current.setFieldValue(this.state.activeElementName, data.keyboardInput);
        this.showKeyboard(false);
    }

    onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") this.handleShift();
    };

    handleShift = () => {
        let layoutName = this.state.layoutName;
    
        this.setState({
          layoutName: layoutName === "default" ? "shift" : "default"
        });
      };

    onSubmit(values, { resetForm }) {
        this.setState({ formSubmitted: true })
        this.props.dispatch(RequestAsset(values));
        //resetForm();
    }

    handleBlur(e) {

    }

    goBack(e) {
        e.preventDefault();

        let { history } = this.props;
        history.goBack();
    }

    renderMessage(goBack) {
        let { theme } = this.context;
        return (<div>
            { theme === "amf" 
                ? (<p className={`contact-description contact-description--${theme}`}>Thank you for your request!</p>)
                : <p className={`contact-description contact-description--${theme}`}>Thank you!</p>
            }
            <button className={`btn btn-primary back-to-assets-btn btn-primary--${theme} float-right`} onClick={goBack}>Back to Assets</button>
        </div>);
    }

    renderKeyboard(layoutName, label, onInputChanged, onInputSubmitted, onKeyPress) {
        let { theme } = this.context;
        return(<div className="osk">
            <div className="osk-mask"></div>
            <div className="osk-content"><div className="contact-row">
            <Formik
                ref={this.keyboardForm}
                initialValues={{keyboardInput: ''}}
                onSubmit={onInputSubmitted}>
                <Form className={`contact-form contact-form--${theme}`}>
                    <div className={`form-group form-group--${theme}`}>
                        <label htmlFor="keyboardInput">{label}</label>
                        <Field 
                            name="keyboardInput"
                            type="text"
                            className={`form-control form-control--${theme}`} 
                        />
                        <button type="submit" className={`btn btn-primary btn-primary--${theme}`}>OK</button>
                    </div>
                </Form>
            </Formik>
            </div>
            <div className="osk-keyboard">
                <Keyboard 
                    layoutName={layoutName} 
                    onChange={onInputChanged}
                    onKeyPress={onKeyPress} />
            </div></div>
        </div>)
    }

    renderForm(initialValues, onSubmit, showKeyboard, theme) {
        
        return (<Formik
            ref={this.form}
            initialValues={initialValues}
            onSubmit={onSubmit}>
            <Form className={`contact-form contact-form--${theme}`}>
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="firstName">First Name</label>
                    <Field
                        name="firstName"
                        type="text"
                        className={`form-control form-control--${theme}`}
                        onFocus={(e) => showKeyboard(e, "First Name")}
                    />
                </div>
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                        name="lastName"
                        type="text"
                        className={`form-control form-control--${theme}`}
                        onFocus={(e) => showKeyboard(e, "Last Name")}
                    />
                </div>
                <div className={`form-group form-group--${theme}`}>
                    <label htmlFor="email">Email Address</label>
                    <Field
                        name="email"
                        type="text"
                        className={`form-control form-control--${theme}`}
                        onFocus={(e) => showKeyboard(e, "Email Address")}
                    />
                </div>
                <button type="submit" className={`btn btn-primary btn-primary--${theme} float-right`}>Send Request</button>
            </Form>
        </Formik>);
    }

    render() {
        let { application, match } = this.props;
        let { formSubmitted, showKeyboard, activeElementTitle, layoutName } = this.state;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        let resource = application.resources.find(r => r.ref == ref);
        let title = resource.fields.find(f => f.name == "title").value;
        let unitEquipment = resource.fields.find(f => f.name == "unitequipment").value
        let { theme } = this.context;
        initialValues.assetName = title;
        initialValues.unitEquipment = unitEquipment;
        switch(resource.resource_type) {
            case "7":
                initialValues.assetType = "Technical Files";
                break;
            case "2":
                initialValues.assetType = "Brochure";
                break;
            case "3":
                initialValues.assetType = "Video";
                break;
            case "6":
                initialValues.assetType = "Product";
                break;
            case "1":
            default:
                initialValues.assetType = "Photo";
                break;
        }

        return (
            <div>
                <div className={`contact-backbutton contact-backbutton--${theme}`}>
                <BackButtonComponent />
                </div>
                <div className="inner-width">
                    
                    <div className={`contact-row contact-row--${theme}`}>
                        <h2 className={`contact-title contact-title--${theme}`}><img src="img/ico-mail-request.svg" /> Request Asset</h2>
                        <p className={`contact-description contact-description--${theme}`}>Please provide your name and email address to request the source file of <span className="italicize"> "{title}"</span>.</p>
                        {theme === 'amf'
                            ? (<p className={`contact-description contact-description--${theme}`}>Your request will be reviewed and if approved, you will receive the asset from <span className="bold">{EmailFrom}</span> in 1-2 business days.</p>)
                            : (<p className={`contact-description contact-description--${theme}`}>Your request will be reviewed and if approved, you will receive the asset in 1-2 business days.</p>)
                        }
                        
                        {formSubmitted
                            ? this.renderMessage(this.goBack)
                            : this.renderForm(initialValues, this.onSubmit, this.showKeyboard, theme)
                        }
                    </div>
                </div>
                {showKeyboard && this.renderKeyboard(layoutName, activeElementTitle, this.onInputChanged, this.onInputSubmitted, this.onKeyPress) }
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(RequestsComponent));
RequestsComponent.contextType = ThemeContext;