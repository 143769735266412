import React, { PureComponent } from 'react';
import SquareOnClickComponent from '../ui/square-onclick-component';
import BreadcrumbsComponent from '../ui/breadcrumbs'
import BackButtonComponent from '../ui/back-button-component';
import { ThemeContext } from '../../theme-context';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const mapStateToProps = state => ({
    application: state.Application
});

class DownloadComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.downloadWindows = this.downloadWindows.bind(this);
        this.downloadMac = this.downloadMac.bind(this);
        this.downloadKiosk = this.downloadKiosk.bind(this);
    }

    buildBreadcrumbLinks() {
        let links = [{
            title: 'Home',
            url: '/'
        }, {
            title: "Download App",
            url: `/download`
        }];
        return links;
    }

    downloadWindows(e) {
    }

    downloadMac(e) {
    }

    downloadKiosk(e) {
    }

    render() {
        let { theme } = this.context;
        return (
            <div>
                <BreadcrumbsComponent links={this.buildBreadcrumbLinks()} />

                <div className="inner-width">
                    <BackButtonComponent />
                    <h1 className={`category-model-page-title category-model-page-title--${theme}`}>Download App</h1>
                    <div className="product-row">
                        <div className={`download-row-container download-row-container--${theme}`}>
                            <a className ={`download-box download-box--${theme}`}href="/updates/latest/win"><SquareOnClickComponent key={0} title={"Windows"} onClick={this.downloadWindows} imageUri={''} /></a> 
                            <a className ={`download-box download-box--${theme}`} href="/updates/latest/darwin"><SquareOnClickComponent key={1} title={"Mac"} onClick={this.downloadMac} imageUri={''} /></a>
                            <a className ={`download-box download-box--${theme}`} href="/updates/latest/kiosk"><SquareOnClickComponent key={2} title={"Kiosk (Windows)"} onClick={this.downloadKiosk} imageUri={''} /></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(DownloadComponent));
DownloadComponent.contextType = ThemeContext;
