import React, { Component } from 'react';
import { ThemeContext } from './theme-context';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import LoaderComponent from './components/loader/component';
import LoginComponent from './components/loader/login-component';
import Application from './components/application/component';
import Solutions from './components/solutions/component'
import Product from './components/product/component';
import ProductDetail from './components/product-detail/component';
import Footer from './components/ui/footer/component';
import Header from './components/ui/header/component';
import SubsystemComponent from './components/subsystem/component';
import UnitEquipmentComponent from './components/unitequipment/component';
import SearchComponent from './components/search/component';
import AssetComponent from './components/assets/component';
import AssetGallery from './components/asset-gallery/component';
import RequestsComponent from './components/requests/component';
import ProcessComponent from './components/process/component';
import ErrorBoundary from './components/error-boundary/component';
import DownloadComponent from './components/download/component';
import EmailComponent from './components/email/component';

const noHeaderFooterRoutes = ['request', 'gallery']

const mapStateToProps = state => ({
  isLoggedIn: state.Application.isLoggedIn,
  isLoading: state.Application.isLoading
});

class RoutesComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { isLoading, isLoggedIn } = this.props;
    let { theme } = this.context;
    if (!isLoggedIn) return (<LoginComponent />)
    if (isLoading) return (<LoaderComponent />)
    let showHeaderFooter = true;
    noHeaderFooterRoutes.map(r => {
      if (window.location.hash.indexOf(r) > -1) showHeaderFooter = false;
    });

    return (
      <div className={`app-container--${theme}`} >
        {showHeaderFooter && (<Header />)}
        <div>
          <Switch>
            <Route exact path="/" render={props =>
              <ErrorBoundary key='1'>
                <Application {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/subsystems/:ref" render={props =>
              <ErrorBoundary key='2'>
                <SubsystemComponent {...props} />
              </ErrorBoundary>
            } />

            <Route path="/subsystems/process/:ref" render={props =>
              <ErrorBoundary key='3'>
                <ProcessComponent {...props} />
              </ErrorBoundary>
            } />

            <Route path="/solutions/:ref" render={props =>
              <ErrorBoundary key='4'>
                <Solutions {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/products/:ref" render={props =>
              <ErrorBoundary key='5'>
                <Product {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/products/:ref/assets" render={props =>
              <ErrorBoundary key='6'>
                <AssetComponent {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/products/:ref/assets/gallery" render={props =>
              <ErrorBoundary key='6'>
                <AssetGallery {...props} />
              </ErrorBoundary>
            } />

            <Route path="/product-detail/:ref/:subRef" render={props =>
              <ErrorBoundary key='7'>
                <ProductDetail {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/unitequipment/:ref" render={props =>
              <ErrorBoundary key='8'>
                <UnitEquipmentComponent {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/unitequipment/:ref/assets" render={props =>
              <ErrorBoundary key='9'>
                <AssetComponent {...props} />
              </ErrorBoundary>
            } />

            <Route exact path="/unitequipment/:ref/assets/gallery" render={props =>
              <ErrorBoundary key='9'>
                <AssetGallery {...props} />
              </ErrorBoundary>
            } />

            <Route path="/request/:ref" render={props =>
              <ErrorBoundary key='10'>
                <RequestsComponent {...props} />
              </ErrorBoundary>
            } />

            <Route path="/search" render={props =>
              <ErrorBoundary key='11'>
                <SearchComponent {...props} />
              </ErrorBoundary>
            } />
            <Route path="/download" render={props =>
              <ErrorBoundary key='12'>
                <DownloadComponent {...props} />
              </ErrorBoundary>
            } />
            <Route path="/email/:ref" render={props =>
              <ErrorBoundary key='13'>
                <EmailComponent {...props} />
              </ErrorBoundary>
            } />
          </Switch>
        </div>
        {showHeaderFooter && (<Footer />)}
      </div>
    );
  }
}

export const Routes = connect(mapStateToProps)(RoutesComponent);
RoutesComponent.contextType = ThemeContext;