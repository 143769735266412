import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BreadcrumbsComponent from '../ui/breadcrumbs';
import BackButtonComponent from '../ui/back-button-component';
import PageHeader from '../ui/page-header/component';
import Markdown from 'react-markdown';
import { fetchMarkdown } from '../../helpers/markdownFetcher';
import { ThemeContext } from '../../theme-context';

const mapStateToProps = state => ({
    application: state.Application
});

const renderProductImageLinks = (ref, prodAccentImgs) => {
    const match = prodAccentImgs.filter(s => s.ref == ref)[0];
    if (match) {
        return (
            <div className="product-image-links">
                {[...Array(match.count)].map((r, i) => {
                    return (<Link key={i} to={`/product-detail/${ref}/${i + 1}`} className="product-image-links-link">CLICK TO EXPAND</Link>)
                })}
            </div>
        );
    }
};

class Product extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            product: null,
            productContent: '',
            links: [],
            validResourceTypes: []
        }

        this.setMarkdownContent = this.setMarkdownContent.bind(this);
    }

    componentWillMount() {
        let { application, match } = this.props;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
        const product = application.solutions.find(s => s.ref == ref);
        const parentSolution = application.solutions.find(s => s.ref == product.parent);
        let productCollection = application.collections.find(c => c.name == product.name);
        let productResources = productCollection
            ? application.resources.filter(r => productCollection.resourceRefs ? productCollection.resourceRefs.indexOf(r.ref) > -1 : false)
            : [];

        // fetch resource types by array index (amf/rbs)
        const resourceType1 = application.resourceTypes[1]; // photo/photo
        const resourceType2 = application.resourceTypes[2]; // document/brochure
        const resourceType3 = application.resourceTypes[3]; // video/video
        const resourceType5 = application.resourceTypes[5]; // products/end product
        const resourceType6 = application.resourceTypes[6]; // 3d document/technical file

        //Determine what assets are available
        let possibleResourceTypes = [resourceType2, resourceType3, resourceType5];
        if(!process.kiosk) { possibleResourceTypes.push(resourceType6); }
        let validResourceTypes = [resourceType1];
        possibleResourceTypes.map(rt => {
            if(productResources.find(r => r.resource_type == rt.ref && r.file_extension != 'md')) { validResourceTypes.push(rt); }
        })

        this.setState({
            product: product,
            links: [
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: `${parentSolution.name}`,
                    url: `/solutions/${parentSolution.ref}`
                },
                {
                    title: `${product.name}`,
                    url: `/products/${product.ref}`
                }
            ],
            validResourceTypes: validResourceTypes
        });

        let productContent = productResources.filter(r => r.resource_type == resourceType2.ref && r.file_extension == "md")[0];
        if (productContent && productContent.url_org) {
            fetchMarkdown(productContent.url_org, this.setMarkdownContent);
        }
    }

    setMarkdownContent(content) {
        this.setState({ productContent: content });
    }

    renderAccent(){
        let { application, match} = this.props;
        let { theme } = this.context;
        let ref = ((match && match.params) ? match.params.ref : '').toString();
       

        switch (application.currentClient) {

            case 'amf':
            var imgAccent = application.prodAccentImgs.find(s => s.ref == ref) ? `./img/product-accent-${ref}.jpg` : './img/product-accent.png';
                return ( 
                <div>
                    <div className="product-detail-accent-image" style={{ backgroundImage: `url('${imgAccent}')` }}></div>
                </div>
                )
            case 'rbs':
                    var imgAccent = application.prodAccentImgs.find(s => s.ref == ref) ? `./img/product-accent-${ref}.png` : `./img/product-accent-${ref}.png`;
                return ( 
                <div className= {`rbs-product-detail-accent-image-container rbs-product-detail-accent-image-container--${theme}`}>
                    <img className="rbs-product-detail-accent-image" src={`${imgAccent}`}/>
                </div>)    
        } 
    }

    render() {
        let { application, match } = this.props;
        let { product, links, productContent, validResourceTypes } = this.state;
        let ref = ((match && match.params) ? match.params.ref : '').toString();

        const imgSrc = `./img/product-${product.ref}.png`;

        return (
            <div className="product">
                <BreadcrumbsComponent links={links} />

                <div className="inner-width">
                    <BackButtonComponent />

                    <PageHeader title={product.name} linkRoute={`/products/${ref}/`} resourceTypesToShow={validResourceTypes} resourceTypes={application.resourceTypes} />

                    {productContent && <Markdown source={productContent} />}

                    <div className={`product-image product-${ref}`}>
                        {renderProductImageLinks(ref, application.prodAccentImgs)}
                        <img className="product-image-img" src={imgSrc} />
                    </div>
                </div>
                { this.renderAccent() }
            </div >
        );
    }
}

export default connect(mapStateToProps)(Product);
Product.contextType = ThemeContext;